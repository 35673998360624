import React, { Component } from 'react'
import { css } from '@emotion/react'

import PropTypes from 'prop-types'

import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import * as Common from '../common'
import * as Transition from '../components/Transition'
import * as Libs from 'libs'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

class PostTemplate extends Component {
  //--------------------------------------
  //  Styles
  //--------------------------------------
  closeButtonCss = () => css`
    position: fixed;
    right: 20px;
    top: 30px;
    z-index: 1000;
    transition: opacity 0.3s cubic-bezier(0.71, 0.01, 0.45, 1.01);
  `
  //--------------------------------------
  //  Scripts
  //--------------------------------------
  contCss = () =>
    css`
       {
        /*margin-top: 84px;*/
      }
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 340, 280, true)};
    `

  constructor (props) {
    super(props)

    this.h1cont = React.createRef()
    this.infoCont = React.createRef()
    this.captionCont = React.createRef()
    this.infoHeaderCont = React.createRef()
    this.articleHeader = React.createRef()

    this.counter = 0
    this.state = {
      ready: false,
      display: false
    }
  }

  componentDidMount () {
    Libs.Common.Config.excuteTransition()
  }
  shouldComponentUpdate (nextProps, nextState) {
    return false
  }

  render () {
    const { data, pageContext } = this.props
    const postData = data.contentfulContentPost
    const { next, prev } = pageContext
    const lang = postData.node_locale
    let filters = []
    if (postData.tags) {
      for (let i = 0; i < postData.tags.length; i++) {
        const tag = postData.tags[i]
        if (tag && tag.group === 'Artist') {
          filters.push(tag.slug)
        }
      }
    }
    const prevURL = `/${lang}/${postData.category.slug}/${prev.slug}/`
    const nextURL = `/${lang}/${postData.category.slug}/${next.slug}/`
    let schemaOrgJSONLDList = []
    schemaOrgJSONLDList.push(
      Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD(
        [
          { name: 'Home', url: `/` },
          {
            name: 'postData',
            url: `/${lang}/${postData.category.slug}/`
          },
          {
            name: postData.name,
            title: postData.title,

            url: `/${lang}/${postData.category.slug}/${postData.slug}/`
          }
        ],
        {
          next: prevURL,
          prev: nextURL
        }
      )
    )
    const rtContCss = () =>
      css`
        width: 92vw;
        max-width: 1600px;
        margin: 0 auto;
      `
    const footerNavCss = () =>
      css`
        ${Libs.Common.Func.getPcSpVwValue('margin-top', 240, 125, false)};
        width: 92vw;
        max-width: 1600px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        .current {
          opacity: 0.2;
        }
        .back-to-works {
          text-transform: capitalize;
        }
        ${Libs.Styles.Mq.moreTab} {
          .back-to-works {
            justify-self: right;
            grid-column: 8 / 12;
            grid-row: 1/2;
          }
          ul {
            grid-column: 2 / 8;
            grid-row: 1/2;
            display: grid;
            row-gap: 1.5rem;
            li {
              position: relative;
              .nav-text {
                position: absolute;
                display: inline-flex;
                top: 0px;
                padding-right: 8px;
                transform: translateX(-100%);
              }
            }
          }
        }

        ${Libs.Styles.Mq.lessPab} {
          row-gap: 2rem;
          text-align: right;
          .back-to-works {
            grid-column: 3 / 12;
          }
          ul {
            grid-column: 3 / 12;

            display: grid;
            row-gap: 1rem;
            li {
              position: relative;
              .nav-text {
                position: absolute;
                display: inline-flex;
                top: 0px;
                padding-right: 8px;
                transform: translateX(-100%);
              }
            }
          }
        }
      `
    const clickHandler = e => {
      e.preventDefault()
      const { dispatch } = this.props
      let langKey = Libs.Common.Func.getLangKey()

      const node = document.querySelector('.project-container')
      const dataset = node.dataset
      let slug = null
      let category = dataset.categoryslug
      if (category === 'works') {
        slug = dataset.current
      }

      Transition.TrigerFadeTransition.TrigerFadeTransition(
        `/${langKey}/${category}/`,
        dispatch,
        slug
      )
    }
    const H1Styles = {
      fontPc: {
        fontSize: 48,
        lineHeight: 48,
        bold: true,
        fontFace: 'sansserif',
        letterSpacing: '-.75'
      },
      fontSp: {
        fontSize: 32,
        lineHeight: 32,
        bold: true,
        fontFace: 'sansserif',
        letterSpacing: '-.5'
      }
    }
    const H2Styles = {
      fontPc: {
        fontSize: 36,
        lineHeight: 36,
        bold: true,
        fontFace: 'sansserif',
        letterSpacing: '-.75'
      },
      fontSp: {
        fontSize: 26,
        lineHeight: 26,
        bold: true,
        fontFace: 'sansserif',
        letterSpacing: '-.5'
      }
    }

    return (
      <>
        <Libs.Common.SEO.SEO
          title={postData.title}
          lang={lang}
          schemaOrgJSONLD={schemaOrgJSONLDList}
          description={
            postData.description
              ? documentToReactComponents(
                  JSON.parse(postData.description.raw)
                )[0].props.children[0]
              : postData.title
          }
          ogImage={
            postData.thumbnail ? postData.thumbnail.gatsbyImageData : null
          }
          link={`${postData.category.slug}/${postData.slug}`}
        />

        <section
          className={'article project-container '}
          data-prev={prev.slug}
          data-next={next.slug}
          data-current={postData.slug}
          data-currentname={postData.name}
          data-categoryname={postData.category.name}
          data-categoryslug={postData.category.slug}
          data-type={'post'}
          css={this.contCss}
          ref={this.container}
        >
          <Libs.Modules.BodyElement
            layoutTypeFunc={Common.Func.getCardType}
            elementTypeFunc={Common.Func.getElementType}
            body={postData.body}
          />
          {postData.content ? (
            <Libs.Atoms.RichText
              css={rtContCss}
              className='rt-cont rt-cont-root'
            >
              {postData.content}
            </Libs.Atoms.RichText>
          ) : (
            ''
          )}
          <div css={footerNavCss} className='footer-nav-cont'>
            <div className='back-to-works'>
              <a href={`/${lang}`} onClick={clickHandler}>
                <Libs.Atoms.Span styles={H2Styles}>
                  Back To {postData.category.name}
                </Libs.Atoms.Span>
              </a>
            </div>
            <ul>
              <li>
                <Libs.Atoms.TriggerLink
                  to={prevURL}
                  transition={
                    Libs.Transition.TrigerPageTransition.TrigerPageTransition
                  }
                >
                  <Libs.Atoms.Span
                    className='nav-text'
                    styles={Common.Config.getSmallStyles()}
                  >
                    PREV
                  </Libs.Atoms.Span>
                  <Libs.Atoms.Span styles={H1Styles}>
                    {prev.title}
                  </Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              </li>
              <li className='current'>
                <Libs.Atoms.Span
                  className='nav-text'
                  styles={Common.Config.getSmallStyles()}
                >
                  CURRENT
                </Libs.Atoms.Span>
                <Libs.Atoms.Span styles={H1Styles}>
                  {postData.name}
                </Libs.Atoms.Span>
              </li>
              <li>
                <Libs.Atoms.TriggerLink
                  to={nextURL}
                  transition={
                    Libs.Transition.TrigerPageTransition.TrigerPageTransition
                  }
                >
                  <Libs.Atoms.Span
                    className='nav-text'
                    styles={Common.Config.getSmallStyles()}
                  >
                    NEXT
                  </Libs.Atoms.Span>
                  <Libs.Atoms.Span styles={H1Styles}>
                    {next.title}
                  </Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              </li>
            </ul>
          </div>
        </section>
      </>
    )
  }
}
PostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default connect(null, null, null, { forwardRef: true })(PostTemplate)

export const pageQuery = graphql`
  query postDataQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulContentPost(id: { eq: $id }) {
      node_locale
      slug
      name
      title
      id
      date(formatString: "MMM Do, YY")

      description {
        raw
      }
      thumbnail {
        gatsbyImageData(width: 600, quality: 70)
      }

      body {
        ... on ContentfulElementTextField {
          ...TextField
        }
      }
      content {
        raw
        references {
          ... on ContentfulElementImage {
            contentful_id
            __typename
            ...Image
          }
          ... on ContentfulElementButton {
            contentful_id
            __typename
            ...Button
          }
          ... on ContentfulElementLink {
            contentful_id
            __typename
            ...Link
          }
          ... on ContentfulElementEmbed {
            contentful_id
            __typename
            ...Embed
          }
          ... on ContentfulRtFlexColumn {
            contentful_id
            __typename
            ...rtFlexColumn
          }
          ... on ContentfulRtTextField {
            contentful_id
            __typename
            ...RtTextField
          }
          ... on ContentfulElementGrid {
            contentful_id
            __typename
            ...ElementGrid
          }
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 1600, quality: 70)
          }
        }
      }
      category {
        name
        slug
      }
      tags {
        name
        slug
        group
      }
    }
  }
`
